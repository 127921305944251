import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { Provider } from "react-redux";
import { createStore } from "redux";
import reducer from "../src/reducer/index";

const store = createStore(
  reducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

const checkForNewVersion = async () => {

  const currentVersion = localStorage.getItem('buildVersion_OBS');

  try {

    const response = await fetch('/version.json', { cache: 'no-store' });
    const { buildVersion_OBS } = await response.json();

    if (currentVersion) {
      if (currentVersion !== buildVersion_OBS) {
        console.log(`New version available: ${buildVersion_OBS}. Current version: ${currentVersion}.`);

        localStorage.setItem('buildVersion_OBS', buildVersion_OBS);
        if ('caches' in window) {
          caches.keys().then((names) => {
            names.forEach((name) => caches.delete(name));
          });
        }
        window.location.reload(true);
      } else {
        console.log(`App is up-to-date. Current version: ${buildVersion_OBS}.`);
      }
    } else {
      console.log(`Setting initial version: ${buildVersion_OBS}`);
      localStorage.setItem('buildVersion_OBS', buildVersion_OBS);
    }
  } catch (error) {
    console.error("Error checking app version:", error);
  }
};

checkForNewVersion();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
